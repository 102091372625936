body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body ::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0px;
}

body ::-webkit-scrollbar-thumb {
  cursor: pointer;
    border-radius: 5px;
  background: #4b5a64;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

body ::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.15);
}

body ::-webkit-scrollbar-thumb:hover {
  background: #4b5a64bf;
}

a {
  color: initial;
  text-decoration: none;
}

#root {
  @media screen {
    height: 100vh;
    display: flex;
    overflow: hidden;
    max-height: 100vh;
    flex-direction: column;
  }
}

@media print {
  #root div {
    overflow: visible;
  }
}

form {
  width: 100%;
}
